import React, { useEffect } from 'react';
import {
    fetchIssueList,
    selectCustomIssueList,
    selectIssueList,
} from '../../features/issue-dashboards/store/issueListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import { issueEditorItem } from '../../features/issue-dashboards/interface/issue-entity.interface';

const IssueListDataLayer = () => {
    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectIssueList);
    const customIssueList: issueEditorItem[] = useSelector(selectCustomIssueList);

    useEffect(() => {
        dispatch(fetchIssueList());
    }, [dispatch]);

    return (
        <EntityListPage
            type="issues"
            list={entity_list || []}
            selections={customIssueList.map((issue) => ({
                title: issue.title,
                code: issue.code.toString(),
            }))}
            defaultSortOrder="asc"
            defaultSortColumn='title'
        />
    );
};

export default IssueListDataLayer;
